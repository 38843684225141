import React from "react";
import "./Homepage.scss";

function Homepage() {
  const myFunction = (e) => {
    e.preventDefault();

    // Copy the text inside the text field
    navigator.clipboard.writeText(76955360);

    // Alert the copied text
    alert("Number Copied");
  };
  return (
    <div id="homepage_container">
      <div id="overlay">
        <img src="/logo.jpg" alt="Bladi Khadra Logo" />
        <p>
          Your donations are not just a gesture—they are a lifeline for
          thousands of innocent displaced who have been forced to flee the
          devastation of the Israeli war on Lebanon. <br />
          These families, torn from their homes and stripped of their basic
          human rights, are in desperate need of help. <br />
          Every dollar you give provides essential food, shelter, and medical
          care, helping them survive in the face of unimaginable hardship. But
          more than that, your support gives them hope—hope that they have not
          been forgotten, that people like you care enough to make a difference.
          <br />
          In times of crisis, we have the power to stand up for humanity. Your
          generosity can bring comfort, safety, and dignity to those who need it
          most. <br />
          Please, let’s not turn away from their suffering—together, we can
          change their lives.
        </p>

        <h2>Donate</h2>
        <p>Donate Using Whish Money</p>
        <div>
          <button onClick={myFunction}>
            Copy Whish Account Number To Clipboard
          </button>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
